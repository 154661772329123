import React from 'react';
import {renderToStaticMarkup} from 'react-dom/server';
import _ from 'lodash';
import parseAndGetBigNumber from '../utils/parseAndGetBigNumber';
import BigNumber from 'bignumber.js';
import Button from '../components/Button';
import Theme from '../themes';
import replaceNewLinesForHtml from '../utils/replaceNewLines';
import DOMPurify from 'dompurify';

BigNumber.config({
  FORMAT: {
    decimalSeparator: ',',
    groupSeparator: '.',
    groupSize: 3
  }
});

const mapping = [
  {
    match: '{{firstName}}',
    resolve: ctx => DOMPurify.sanitize(_.get(ctx, ['firstName'], ''))
  },
  {
    match: '{{amount}}',
    resolve: ctx => {
      const amountHasValue = ctx.amount_m !== '' && !_.isNil(ctx.amount_m);
      const val = amountHasValue ? ctx.amount_m : ctx.customAmount_m;

      return toBigNumber(val);
    }
  },
  {
    match: '{{amountLabel}}',
    resolve: ctx => DOMPurify.sanitize(_.get(ctx, ['amountLabel'], ''))
  },
  {
    match: '{{frequency}}',
    resolve: ctx => {
      switch (ctx.frequency) {
        case 'oneOff':
          return 'eenmalig';
        case 'monthly':
          return 'maand';
        case 'quarterly':
          return 'kwartaal';
        case 'halfYearly':
          return 'half jaar';
        case 'yearly':
        default:
          return 'jaar';
      }
    }
  },
  {
    match: '({{label:\\")(.+)(\\")(, url:\\")(.+)(\\")}}',
    resolve: (ctx, text, theme) => {
      const matches = text.match(/({{label:")(.+)(")(, url:")(.+)(")}}/);

      if (_.isEmpty(matches)) {
        return null;
      }

      const label = matches[2];
      const url = matches[5];

      let button = renderToStaticMarkup(<Theme themeFromState={theme}><Button label={label} /></Theme>);
      button = _.replace(button, '<button', `<button type="button" onclick="window.open('${url}')"`);
      return button;
    }
  },
  {
    match: '{{externalVariable}}',
    resolve: ctx => DOMPurify.sanitize(_.get(ctx, ['externalVariable'], ''))
  },
  {
    match: '{{externalReferenceName}}',
    resolve: ctx => DOMPurify.sanitize(_.get(ctx, ['externalReferenceName'], ''))
  },
  {
    match: '{{externalReferenceUrl}}',
    resolve: ctx => DOMPurify.sanitize(_.get(ctx, ['externalReferenceUrl'], ''))
  },
  {
    match: '{{variableFieldAnswer}}',
    resolve: ctx => {
      const answer = ctx.variableFieldAnswer || ctx.variableFieldDefaultAnswer || '';
      return DOMPurify.sanitize(replaceNewLinesForHtml(answer));
    }
  },
  {
    match: '{{variableFieldLabel}}',
    resolve: ctx => ctx.variableFieldLabel || ''
  },
  {
    match: '{{orderDetails}}',
    resolve: ctx => {
      const quantity = _.toInteger(ctx.productAmount);
      const productName = _.get(ctx, ['productName']);
      const productNamePlural = _.get(ctx, ['productNamePlural']);

      if (quantity > 1) {
        return `${quantity} ${productNamePlural}`;
      } else if (quantity === 1) {
        return `${quantity} ${productName}`;
      } else {
        return '-';
      }
    }
  },
  {
    match: '{{productName}}',
    resolve: ctx => ctx.productName || ''
  },
  {
    match: '{{productNamePlural}}',
    resolve: ctx => ctx.productNamePlural || ''
  },
  {
    match: '{{period}}',
    resolve: ctx => `${ctx.period} maanden`
  },
  {
    match: '{{adminCostContribution}}',
    resolve: ctx => `${toBigNumber(_.get(ctx, 'adminCostContributionAmount_m'), '0')}`
  },
  {
    match: '{{donationAmount}}',
    resolve: ctx => {
      const amountHasValue = ctx.donationAmount_m !== '' && !_.isNil(ctx.donationAmount_m);
      const val = amountHasValue ? ctx.donationAmount_m : ctx.donationCustomAmount_m;
      return toBigNumber(val);
    }
  }
];
export default function parseText(ctx, text, theme) {
  if (_.isNil(text)) {
    return '';
  }

  mapping.forEach(item => {
    text = text.replace(new RegExp(item.match, 'g'), item.resolve(ctx, text, theme));
  });

  return text;
}

function toBigNumber(value) {
  const bigAmount = parseAndGetBigNumber(value);

  if (bigAmount === undefined) {
    return;
  }

  return bigAmount.toFormat(2);
}
